import React from 'react';
import './Overlay.scss'

function Overlay(props) {
  return (
    <div onClick={props.onClose} className={`overlay ${props.isActive ? 'overlay_active' : ''}`}/>
  );
}

export default Overlay;
