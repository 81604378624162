import React from 'react';
import Form from '../Form/Form';
import api from '../../utils/Api';

function Patch(props) {


  function onPatchObject(object) {
    api.getObject(object)
      .then(res => res[0].equipments_ids.map(item => {
        api.getEquipment(item)
          .then(res => {
            if (res.type === 'Заднее табло') {
              const body = {
                'equipment_type_code': 'back_board',
                'equipment_model_code': '64x128p4-eth_back',
                'equipment_manufacturer_code': 'iskra_ufa',
              };
              api.patchEquipment(item, body)
                .then(res => console.log(res));
            }
            return res;
          });
        return res;
      }))
      .catch(err => console.log(err));
  }

  function onPatchObjects(values) {
    const arr = values.object.split(' ');
    arr.forEach(object => {
      setTimeout(() => onPatchObject(object), 100);
    });
  }

  return (
    <section className="text-white text-center flex m-auto pt-16 w-2/3 flex-col">
      <div>
        <h2>Проверить количество оборудования</h2>
        <button
          className="hover:opacity-70 transition-opacity duration-300 ease-in-out mt-4 border-2 rounded-2xl p-2 bg-cyan-900"
          onClick={api.getAllObjects}>Загрузить объекты
        </button>
      </div>
      <Form name={'patch'} onSubmit={onPatchObjects}/>
    </section>
  );
}

export default Patch;
