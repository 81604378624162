export const URL_PROXY = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : '')
export const API_URL = (process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.service.euromobile.ru')
export const REGEX_NAME = /^[а-яА-Яa-zA-ZЁёәіңғүұқөһӘІҢҒҮҰҚӨҺ\-\s]*$/;

export const VERSION = 'afdd0cc9'

export const PARKS_COORDINATES = {
  "gorskaya": [60.02953, 29.97507],
  "vitebskiy": [59.87809, 30.34663],
  "zelenogorsk": [60.20424, 29.69217],
  "ap2": [60.02739, 30.26371],
  "ap5": [59.85980, 30.26114],
  "ap6": [59.83470, 30.30781],
  "ap7": [59.83470, 30.30781],
  "ap8": [59.96861, 30.44396],
  "ulan_ude": [51.833395, 107.655092]
}

export const PARKS_ADDRESSES = {
  "gorskaya": {address: 'Третий парк "Горская"', coordinates: PARKS_COORDINATES.gorskaya},
  "vitebskiy": {address: 'Третий парк "Витебский"', coordinates: PARKS_COORDINATES.vitebskiy},
  "zelenogorsk": {address: 'Третий парк "Зеленогорск"', coordinates: PARKS_COORDINATES.zelenogorsk},
  "ap2": {address: 'ПАТ АП-2', coordinates: PARKS_COORDINATES.ap2},
  "ap5": {address: 'ПАТ АП-5', coordinates: PARKS_COORDINATES.ap5},
  "ap6": {address: 'ПАТ АП-6', coordinates: PARKS_COORDINATES.ap6},
  "ap7": {address: 'ПАТ АП-7', coordinates: PARKS_COORDINATES.ap7},
  "ap8": {address: 'ПАТ АП-8', coordinates: PARKS_COORDINATES.ap8},
  "ulan_ude": {address: 'МУП "Городские Маршруты" Улан-Удэ', coordinates: PARKS_COORDINATES.ulan_ude},
}

export const COMPANIES = {
  'third_park': 4,
  'pat': 3,
  'em_bo_id': 5,
  'em_storage_id': 14,
  'ulan_ude': 10,
}


export const PRICE_LIST_WARRANTY = '31795'

// export const GORSKAYA = {address: 'Третий парк "Горская"', coordinates: GORSKAYA_COORDINATES}
// export const VITEBSKIY = {address: 'Третий парк "Витебский"', coordinates: VITEBSKIY_COORDINATES}
// export const ZELENOGORSK = {address: 'Третий парк "Зеленогорск"', coordinates: ZELENOGORSK_COORDINATES}
// export const AP2 = {address: 'ПАТ АП-2', coordinates: AP2_COORDINATES}
// export const AP5 = {address: 'ПАТ АП-5', coordinates: AP5_COORDINATES}
// export const AP7 = {address: 'ПАТ АП-7', coordinates: AP7_COORDINATES}
// export const AP8 = {address: 'ПАТ АП-8', coordinates: AP8_COORDINATES}
// export const AP6 = {address: 'ПАТ АП-6', coordinates: AP6_COORDINATES}

export const OKDESK_URL = 'https://tech.euromobile.ru/'

export const EQ_ARRAY = [{ name: ['first equipment', 'second'] }, { name: 'second equipment' }, { name: 'third equipment' }]
export const FAULTY_EQ_STORAGE_ID = '1183'
