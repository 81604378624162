import React, {useContext, useEffect} from 'react';
import {Link, Navigate} from 'react-router-dom';
import './Home.scss';
import CurrentUserContext from '../../hoc/CurrentUserContext';
import Loader from '../Loader/Loader';

function Home(props) {


  const currentUser = useContext(CurrentUserContext);

  return (
    currentUser.isAdmin ?
    <section className="home">
      <h1 className="home__title">Портал автоматизации работы с Okdesk</h1>
      <Link to="/close" className="link home__link">Закрыть заявку</Link>
    </section> : <Navigate to="close"/>
  );
}

export default Home;
