import React from 'react';
import './Popup.scss';

function Popup({ children, isOpen }) {
  return (
    <>
      <div className={`popup ${isOpen ? 'popup_open' : ''}`}>
        {children}
      </div>
    </>
  );
}

export default Popup;
