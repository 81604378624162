import React from 'react';
import './ProgressBar.scss';
import Loader from '../Loader/Loader';

function ProgressBar({ progressBarValue }) {
  return (
    <div className="progressBar">
      <Loader type="popup"/>
      <div className="progressBar__filler" style={{ width: `${progressBarValue}%` }}></div>
    </div>
  );
}

export default ProgressBar;
