import React, {useState} from 'react';
import Select from 'react-select';
import './Form.scss';


function Form(props) {

  const [data, setData] = useState('');
  const [objectAddressSelect, setObjectAddressSelect] = useState(undefined);
  const [companySelect, setCompanySelect] = useState(undefined);
  const [modelSelect, setModelSelect] = useState(undefined);
  const [storageSelect, setStorageSelect] = useState(undefined);

  const customStyles = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      height: '48px',
      borderRadius: '6px',
      border: 'none',
    }), option: (defaultStyles) => ({
      ...defaultStyles, fontSize: '12px',
    })
  };

  function handleChange(e) {
    setData(e.target.value);
  }

  function handleModelSelectChange(selectedOption) {
    setModelSelect(selectedOption.value);
  }

  function handleStorageSelectChange(selectedOption) {
    setStorageSelect(selectedOption.value);
  }

  function handleObjectAddressSelectChange(selectedOption) {
    setObjectAddressSelect(selectedOption.value);
  }

  function handleCompanySelectChange(selectedOption) {
    setCompanySelect(selectedOption.value);
  }

  function submitHandler(e) {
    e.preventDefault();
    props.onSubmit({ data, objectAddressSelect, companySelect, modelSelect, storageSelect });
  }

  return (
    <div className="add-eq-form">
      <h3 className="">{props.title}</h3>
      <form onSubmit={submitHandler} className="add-eq-form__form">
        <div className="add-eq-form__input-wrap">
          <input type="text" required min="10" className="" id="value" name="value" onChange={handleChange}/>
          <button type="submit" className="button">Добавить</button>
        </div>
        <div className="add-eq-form__select-wrap">
          {props.type === 'addObject' || props.type === 'eqInStorage' || props.type === 'eqOnBoard' ?
            <Select placeholder="Выберите компанию" styles={customStyles} options={props.companiesOptions}
                    className="add-eq-form__select" required onChange={handleCompanySelectChange}/> : ''}
          {props.type === 'addObject' ?
            <Select placeholder="Выберите адрес" styles={customStyles} options={props.objectsAddressesOptions}
                    className="add-eq-form__select" required onChange={handleObjectAddressSelectChange}/> : ''}
          {props.type === 'addObject' ?
            <Select placeholder="Выберите модель" styles={customStyles} options={props.modelsOptions}
                    className="add-eq-form__select" required onChange={handleModelSelectChange}/> : ''}
          {props.type === 'eqInStorage' ?
            <Select placeholder="Выберите склад" styles={customStyles} options={props.storagesOptions}
                    className="add-eq-form__select" required onChange={handleStorageSelectChange}/> : ''}
        </div>
      </form>
    </div>
  );
}

export default Form;
