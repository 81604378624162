import React, {useEffect, useState} from 'react';
import './CreateIssue.scss';
import {Field, Form, Formik} from 'formik';
import {COMPANIES, FAULTY_EQ_STORAGE_ID} from '../../utils/constants';
import api from '../../utils/Api';
import Loader from '../Loader/Loader';
import {CreateIssueSchema} from '../../utils/validation';

function CreateIssue(props) {

  const [isLoaded, setIsLoaded] = useState(true);
  const [equipmentsList, setEquipmentsList] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    api.getEquipmentsList(FAULTY_EQ_STORAGE_ID)
      .then(res => {
        setEquipmentsList(res);
      })
      .then(() => setIsLoaded(false))
      .catch(e => console.log(`Невозможно выполнить запрос. Ошибка: ${e}`));
  }, []);

  useEffect(() => {
    console.log(equipmentsList);
    // if (equipmentsList) {
    //
    // }
  }, [equipmentsList]);

  function createIssue(title, eq, object) {
    const body = {
      'issue': {
        'title': title,
        'company_id': COMPANIES.em_storage_id,
        'type': 'inner_service',
        'equipment_ids': [
          eq
        ],
        'custom_parameters': {
          'last_object': object
        }
      }
    };
    api.createIssue(body)
      .then(() => setStatusMessage('Заявки успешно созданы'))
      .catch(err => setStatusMessage(`Не удалось создать заявки. Ошибка: ${err.message}`));
  }

  function onSubmit(value) {
    value.checked.forEach(item => {
      value.equipments.forEach(eq => {
        if (item === String(eq.id)) {
          createIssue(eq.parameters[0].value, eq.id, eq.parameters[1].value);
        }
      });
    });
  }

  if (isLoaded) {
    return <Loader/>;
  } else {
    return (
      <section className="create-issue">
        <Formik initialValues={{ equipments: equipmentsList, checked: [] }}
                onSubmit={onSubmit} validationSchema={CreateIssueSchema}>
          {({ values }) => (
            <Form>
              {equipmentsList.map((item, index) =>
                <div key={`issue-${index}`} className="create-issue__eq-desc">
                  <label className="create-issue__eq">
                    <Field disabled={!values.equipments[index].parameters[0].value} type="checkbox" name="checked" value={`${item.id}`}/>
                    <div>
                      {`${item.equipment_kind.name} ${item.equipment_manufacturer.name} ${item.equipment_model.name} `}
                      <span>{`SN: ${item.serial_number}`}</span>
                    </div>
                  </label>
                  <label className="create-issue__defect">
                    <p>ТС:</p>
                    <Field className="create-issue__object-input" type="text"
                           name={`equipments[${index}].parameters[1].value`}/>
                    <p>дефект:</p>
                    <Field className="create-issue__defect-input" type="text" component="textarea" cols="40" wrap="soft"
                           name={`equipments[${index}].parameters[0].value`}/>
                  </label>
                </div>
              )}
              <p
                className={`close-issue-form__message ${statusMessage === 'Заявки успешно созданы' ? 'close-issue-form__message_ok' : 'close-issue-form__message_err'}`}>{statusMessage}</p>
              <button type="submit" className="button">Создать заявки</button>
            </Form>
          )}
        </Formik>
      </section>
    );
  }
}

export default CreateIssue;
