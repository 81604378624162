import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import './Layout.scss';
import Overlay from '../Overlay/Overlay';
import {VERSION} from '../../utils/constants';

function Layout(props) {


  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  function handleLinkClick() {
    if (isBurgerOpen) {
      setTimeout(() => setIsBurgerOpen(false), 300)
    }
  }

  function toggleMenuOpen() {
    if (isBurgerOpen) {
      setIsBurgerOpen(false);
    } else {
      setIsBurgerOpen(true);
    }
  }

  return (
    <>
      <Navbar handleLinkClick={handleLinkClick} handleBurgerClick={toggleMenuOpen} isMenuOpen={isBurgerOpen} onLogout={props.onLogout}/>
      <Overlay onClose={toggleMenuOpen} isActive={isBurgerOpen}/>
      <main className="main">
        <Outlet/>
        <div className='version'>{`commit: ${VERSION}`}</div>
      </main>
    </>
  );
}

export default Layout;
