import React, {useEffect} from 'react';
import './SignIn.scss';
import logo from '../../images/em_logo.png';
import {useNavigate} from 'react-router-dom';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {SigninSchema} from '../../utils/validation';

function SignIn(props) {

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('login')) {
      navigate('/', { replace: true });
    }
  }, []);

  function submitHandler(values) {
    props.handleLogin(values);
  }

  return (
    <section className="sign-in">
      <Formik initialValues={{
        email: '',
        password: ''
      }} validationSchema={SigninSchema} onSubmit={submitHandler}>
        {({ isValid }) => (
          <Form>
            <img src={logo} alt=""/>
            <h1 className='title'>Вход в сервисный портал</h1>
            <Field placeholder="Введите E-Mail" name="email"
                   className="input"
                   type="email"/>
            <ErrorMessage name="email" component="div" className="sign-in__validation-error"/>
            <Field placeholder="Введите пароль" name="password"
                   className="input"
                   type="password"/>
            <ErrorMessage name="password" component="div" className="sign-in__validation-error"/>
            <button disabled={!isValid} type="submit"
                    className="link">Войти
            </button>
            <p className={`sign-in__error ${props.error ? 'sign-in__error_active' : ''}`}>{props.error}</p>
          </Form>
        )
        }
      </Formik>
    </section>
  );
}

export default SignIn;
