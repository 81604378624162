import {Route, Routes, useNavigate} from 'react-router-dom';
import Layout from './Layout/Layout';
import Home from './Home/Home';
import Add from './Add/Add';
import Patch from './Patch/Patch';
import Generate from './Generate/Generate';
import Close from './Close/Close';
import SignIn from './SignIn/SignIn';
import {useEffect, useState} from 'react';
import api from '../utils/Api';
import ProtectedRouteElement from '../hoc/ProtectedRoute';
import AuthContext from '../hoc/AuthContext';
import CurrentUserContext from '../hoc/CurrentUserContext';
import LoadingContext from '../hoc/LoadingContext';
import Loader from './Loader/Loader';
import Register from './Register/Register';
import CreateIssue from './CreateIssue/CreateIssue';
import Settings from './Settings/Settings';
import {PRICE_LIST_WARRANTY} from '../utils/constants';

export default function App() {

  const [loggedIn, setLoggedIn] = useState(undefined);
  const [currentUser, setCurrentUser] = useState({});
  const [entryError, setEntryError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSNChangePopupOpen, setIsSNChangePopupOpen] = useState(false);
  const [eqTypeList, setEqTypeList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getEqTypes();
      // getPriceList(PRICE_LIST_WARRANTY);
    }
  }, [loggedIn]);

  function getEqTypes() {
    api.getIssuesParams()
      .then(res => {
        res.forEach(list => {
          if (list.code === 'eq_type') {
            setEqTypeList(list.field_type_values.map(item => {
              return { value: item, label: item };
            }));
          }
        });
      })
      .catch(() => setEqTypeList([{ label: 'Ошибка запроса' }]));
  }

  function getPriceList(id) {
    api.getPriceList(id)
      .then(res => setServicesList(res.map(item => {
        return {
          value: item.id, label: item.name
        };
      })))
      .catch(() => setServicesList([{ label: 'Ошибка запроса' }]));
  }

  function checkAuth() {
    if (localStorage.getItem('login') === 'true') {
      api.auth()
        .then(userInfo => {
          setLoggedIn(true);
          setCurrentUser(userInfo);
        })
        .catch(() => {
          setLoggedIn(false);
          localStorage.clear();
          console.log('Ошибка аутентификации');
        });
    } else {
      console.log('Требуется авторизация');
      setLoggedIn(false);
    }
  }

  function handleLogin(values) {
    values.email = values.email.toLowerCase()
    api.login(values)
      .then(() => {
        setLoggedIn(true);
        localStorage.setItem('login', 'true');
      })
      .then(() => checkAuth())
      .then(() => navigate('/', { replace: true }))
      .catch(err => {
        if (err.validation) {
          setEntryError(err.validation.body.message);
        } else {
          console.log(err);
          setEntryError(err.message);
        }
      });
  }

  function handleLogout() {
    api.logout()
      .then(() => {
        setLoggedIn(false);
        localStorage.clear();
        setCurrentUser({});
      })
      .then(() => navigate('./signin'))
      .catch(err => console.log(err));
  }

  function openConfirmPopup() {
    setIsConfirmPopupOpen(true);
  }

  function openSNChangePopup() {
    setIsSNChangePopupOpen(true);
  }

  function closePopup() {
    setIsSNChangePopupOpen(false);
    setIsConfirmPopupOpen(false);
  }


  if (loggedIn === undefined) {
    return <Loader/>;
  } else return (
    <AuthContext.Provider value={loggedIn}>
      <CurrentUserContext.Provider value={currentUser}>
        <LoadingContext.Provider value={isLoading}>
          <Routes>
            <Route path="/" element={<Layout onLogout={handleLogout}/>}>
              <Route index element={<ProtectedRouteElement element={Home} loggedIn={loggedIn}/>}/>
              <Route path="generate" element={<ProtectedRouteElement element={Generate}/>}/>
              <Route path="close" element={<ProtectedRouteElement servicesList={servicesList} eqTypeList={eqTypeList} element={Close} onClosePopup={closePopup}
                                                                  isConfirmOpen={isSNChangePopupOpen}
                                                                  onPopupOpen={openConfirmPopup}/>}/>
              {currentUser.isAdmin ? <Route path="add" element={<ProtectedRouteElement element={Add}/>}/> : ''}
              {currentUser.isAdmin ? <Route path="patch" element={<ProtectedRouteElement element={Patch}/>}/> : ''}
              {currentUser.isAdmin ?
                <Route path="register" element={<ProtectedRouteElement element={Register}/>}/> : ''}
              {currentUser.isAdmin ?
                <Route path="create-issue" element={<ProtectedRouteElement element={CreateIssue}/>}/> : ''}
              {currentUser.isAdmin ?
                <Route path="settings" element={<ProtectedRouteElement element={Settings}/>}/> : ''}
              <Route path="loader" element={<ProtectedRouteElement element={Loader}/>}/>
            </Route>
            <Route path="signin" element={<SignIn handleLogin={handleLogin} error={entryError}/>}/>
          </Routes>
        </LoadingContext.Provider>
      </CurrentUserContext.Provider>
    </AuthContext.Provider>


  );
}
