import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import './Settings.scss';
import SettingTab from './SettingTab/SettingTab';

function Settings(props) {

  return (<section className="settings">
    <h1 className="settings__title title">Настройки</h1>
    <div className="settings__tab">
      <Tabs>
        <TabList>
          <Tab>Парки</Tab>
          <Tab>Компании</Tab>
          <Tab>Модели</Tab>
          <Tab>Склады</Tab>
        </TabList>
        <TabPanel>
          <SettingTab name={'objects_addresses'}/>
        </TabPanel>
        <TabPanel>
          <SettingTab name={'companies'}/>
        </TabPanel>
        <TabPanel>
          <SettingTab name={'models'}/>
        </TabPanel>
        <TabPanel>
          <SettingTab name={'storages'}/>
        </TabPanel>
      </Tabs>
    </div>
  </section>);
}

export default Settings;
