import React, {useContext, useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import api from '../../utils/Api';
import './Close.scss';
import Confirm from '../Popup/Confirm/Confirm';
import Popup from '../Popup/Popup';
import ChangeSN from '../Popup/ChangeSN/ChangeSN';
import CurrentUserContext from '../../hoc/CurrentUserContext';
import {OKDESK_URL} from '../../utils/constants';
import ChangeEq from '../ChangeEq/ChangeEq';
import Loader from '../Loader/Loader';

function Close(props) {

  const currentUser = useContext(CurrentUserContext);

  const [engineersList, setEngineersList] = useState([]);
  const [expandedIssuesList, setExpandedIssuesList] = useState([]);
  const [selectEngineer, setSelectEngineer] = useState({ id: undefined });
  const [selectIssue, setSelectIssue] = useState(undefined);
  const [objectEqList, setObjectEqList] = useState([]);
  const [engineerEqList, setEngineerEqList] = useState([]);
  const [selectObjectEq, setSelectObjectEq] = useState('');
  const [selectEqType, setSelectEqType] = useState(null);
  const [comment, setComment] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [photosToUpload, setPhotosToUpload] = useState(undefined);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSNChangePopupOpen, setIsSNChangePopupOpen] = useState(false);
  const [objectEqLoading, setObjectEqLoading] = useState(false);
  const [engineerEqLoading, setEngineerEqLoading] = useState(false);
  const [issuesListLoading, setIssuesListLoading] = useState(false);
  const [closeIssueIsLoading, setCloseIssueIsLoading] = useState(false);
  const [formValue, setFormValue] = useState([]);
  const [objEqRef, setObjEqRef] = useState(undefined);
  const [isResetForm, setIsResetForm] = useState(false);
  const [isDiagIncluded, setIsDiagIncluded] = useState(true);
  const [availablePriceList, setAvailablePriceList] = useState([]);
  const [diagnosticsServiceId, setDiagnosticsServiceId] = useState(null);

  const selectIssueRef = useRef();
  const selectEqTypeRef = useRef();
  const commentInputRef = useRef();
  const fileInputRef = useRef();

  const customStyles = {
    control: (defaultStyles) => ({
      ...defaultStyles, height: '48px', borderRadius: '6px', border: 'none',
    }), option: (defaultStyles) => ({
      ...defaultStyles, fontSize: '12px',
    })
  };

  // загрузка информации в выпадающие списки при открытии страницы
  useEffect(() => {
    if (currentUser.isAdmin) {
      getEngineers();
    } else {
      setSelectEngineer({ id: currentUser.okdeskId, storage: currentUser.storageId, company: currentUser.companyId });
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectEngineer.id) {
      setSelectIssue(null);
      setExpandedIssuesList([]);
      setObjectEqList([]);
      setEngineerEqLoading(true);
      getIssues(selectEngineer.id);
      getEngineerEquipmentList();
    }
  }, [selectEngineer]);

  useEffect(() => {
    if (selectIssue) {
      setObjectEqList([]);
      setObjectEqLoading(true);
      getObjectEquipmentsList();
      getAvailablePriceList();
    }
  }, [selectIssue]);

  function getObjectDetails(id) {
    return api.getObjectName(id);
  }

  function getEngineers() {
    api.getEngineers()
      .then(res => res.map(item => {
        return { value: { id: item.id, storage: item.comment }, label: item.last_name }; // ID склада хранится в комментарии в карточке сотрудника
      }))
      .then(res => setEngineersList(res))
      .catch(() => setEngineersList([{ label: 'Ошибка запроса' }]));
  }

  function getAvailablePriceList() {
    setDiagnosticsServiceId(null);
    api.getAvailablePriceList(selectIssue.id)
      .then(res => setAvailablePriceList(res.map(item => {
        if (item.code === 'diagnostics') {
          setDiagnosticsServiceId(item.id);
        }
        return {
          value: item.id, label: item.name, code: item.code
        };
      })))
      .catch(() => setAvailablePriceList([{ label: 'Ошибка запроса' }]));
  }

  function getIssues(id) {
    setIssuesListLoading(true);
    api.getIssues(id)
      .then(issues => {
        if (issues.length === 0) {
          setExpandedIssuesList([{ label: 'Не найдено активных заявок' }]);
          return;
        }
        Promise.all(issues.map(issue => getObjectDetails(issue.service_object.id)))
          .then(objectDetails => {
            const expandedIssuesList = issues.map((issue, index) => ({
              value: { id: issue.id, object: objectDetails[index], defect: issue.title },
              label: `[#${issue.id}] ТС:${objectDetails[index].name} ${issue.title}`
            }));
            setExpandedIssuesList(expandedIssuesList);
          });
      })
      .catch(() => setExpandedIssuesList([{ label: 'Не найдено активных заявок' }]))
      .finally(() => setIssuesListLoading(false));
  }

  function getEngineerEquipmentList() {
    api.getEquipmentsList(selectEngineer.storage)
      .then(res => setEngineerEqList(res.map(item => {
        return {
          value: item,
          label: `${item.equipment_kind.name} ${item.equipment_manufacturer.name} ${item.equipment_model.name} SN: ${item.serial_number}`
        };
      })))
      .then(() => setEngineerEqLoading(false))
      .catch(() => {
        setEngineerEqLoading(false);
        setEngineerEqList([{ label: 'Ошибка запроса' }]);
      });
  }

  function getObjectEquipmentsList() {
    api.getEquipmentsList(selectIssue.object.id)
      .then(res => setObjectEqList(res.map(item => {
        return {
          value: item,
          label: `${item.equipment_kind.name} ${item.equipment_manufacturer.name} ${item.equipment_model.name} SN: ${item.serial_number}`
        };
      })))
      .then(() => setObjectEqLoading(false))
      .catch(() => {
        setObjectEqLoading(false);
        setObjectEqList([{ label: 'Ошибка запроса' }]);
      });
  }

  function eqNameAssembler(data) {
    return `${data.equipment_kind.name} ${data.equipment_manufacturer.name} ${data.equipment_model.name}`;
  }

  function addServices(data) {
    let body = {};
    getObjectDetails(selectIssue.object.id)
      .then((res) => {
        // Не трогать форматирование
        body = {
          'issue_service': {
            'service_id': data.selectService, 'quantity': 1, 'performer_id': selectEngineer.id, 'comment': `${res.name}
${comment.length !== 0 ? `${comment}
` : ''} снял: ${data.selectObjEq ? `${eqNameAssembler(data.selectObjEq)}` : `${eqNameAssembler(data.selectEngEq)}`} SN: ${data.selectObjEq ? data.selectObjEq.serial_number : data.newSn}
поставил: ${eqNameAssembler(data.selectEngEq)} SN: ${data.selectEngEq.serial_number}`
          }
        };
        api.addService(selectIssue.id, body)
          .then(() => Promise.resolve('ok'))
          .catch(err => Promise.reject(err));
      })
      .catch(err => Promise.reject(`Заявка закрыта с ошибкой. Не удалось добавить спецификацию! Ошибка: ${err}`));
  }

  function addDiagnosticService() {
    if (diagnosticsServiceId) {
      const body = {
        'issue_service': {
          'service_id': diagnosticsServiceId, 'quantity': 1, 'performer_id': selectEngineer.id,
        }
      };
      api.addService(selectIssue.id, body)
        .then(() => Promise.resolve('ok'))
        .catch(err => Promise.reject(`Заявка закрыта с ошибкой. Не удалось добавить спецификацию! Ошибка: ${err}`));
    }

    //
  }

  function addComment(data) {
    // Не трогать форматирование
    const body = {
      'comment': {
        'content': `${comment.length !== 0 ? `${comment}<br />` : ''}снял: ${data.selectObjEq ? `${eqNameAssembler(data.selectObjEq)}` : `${eqNameAssembler(data.selectEngEq)}`} SN: ${data.selectObjEq ? data.selectObjEq.serial_number : data.newSn}<br />поставил: ${eqNameAssembler(data.selectEngEq)} SN: ${data.selectEngEq.serial_number}<br />Закрыто через портал`,
        'public': false,
        'author_id': selectEngineer.id,
      }
    };
    api.addComment(selectIssue.id, body)
      .catch(() => Promise.reject('Заявка закрыта с ошибкой. Не удалось добавить комментарий!'));
  }

  function changeSN(sn) {
    setStatusMessage('');
    api.patchEquipment(selectObjectEq.id, { serial_number: sn })
      .then(() => {
        setObjectEqLoading(true);
        getObjectEquipmentsList();
      })
      .then(() => {
        objEqRef.current.props.value.value.serial_number = sn;
        const value = objEqRef.current.props.value.value;
        objEqRef.current.props.value.label = `${value.type} ${value.manufacturer} ${value.model} SN: ${value.serial_number}`;
      })
      .catch(err => {
        setStatusMessage(`SN не сменен. Ошибка: ${err.serial_number ? err.serial_number : err}`);
      });
  }

  async function submitPhotos() {
    if (photosToUpload) {
      const body = new FormData();
      body.append('content', `Добавлены фото`);
      body.append('author_id', selectEngineer.id);
      for (let i = 0; i < photosToUpload.length; i++) {
        body.append('attachment', photosToUpload[i]);
      }
      await api.addPhotos(selectIssue.id, body);
    }
  }

  function changeIssueStatus() {
    const body = {
      code: 'completed',
      comment: 'Заявка закрыта',
      comment_public: 'false',
      'custom_parameters': {
        eq_type: selectEqType
      }
    };
    return api.closeIssue(selectIssue.id, body);
  }

  function changeEquipment(data) {
    if (!data.newSn) {
      Promise.all([api.patchEquipment(data.selectEngEq.id, {
        maintenance_entity_id: String(selectIssue.object.id),
        company_id: String(selectIssue.object.company_id),
        custom_parameters: {
          last_defect: selectIssue.defect,
          last_object: selectIssue.object.name
        }
      }), api.patchEquipment(data.selectObjEq.id, {
        maintenance_entity_id: String(selectEngineer.storage),
        company_id: selectEngineer.company,
      })])
        .catch(() => Promise.reject('Заявка закрыта с ошибкой. Не удалось перенести обрудование!'))
        .then(() => {
          addServices(data);
        })
        .then(() => {
          addComment(data);
        })
        .catch(err => setStatusMessage(`Заявка не закрыта или закрыта частично, требуется проверка. Ошибка: ${err.message}`));
    } else {
      closeIssueWithoutEqRemove(data);
    }
  }

  function closeIssueWithoutEqRemove(data) {
    api.patchEquipment(data.selectEngEq.id, {
      maintenance_entity_id: String(selectIssue.object.id),
      company_id: String(selectIssue.object.company_id),
      serial_number: data.newSn,
      custom_parameters: {
        last_defect: selectIssue.defect,
        last_object: selectIssue.object.name
      }
    })
      .catch(() => Promise.reject('Заявка закрыта с ошибкой. Не удалось перенести обрудование!'))
      .then(() => {
        api.patchEquipment(data.selectEngEq.id, {
          maintenance_entity_id: String(selectEngineer.storage), company_id: selectEngineer.company
        })
          .catch(() => Promise.reject('Заявка закрыта с ошибкой. Не удалось перенести обрудование!'));
      })
      .then(() => {
        addServices(data);
      })
      .then(() => {
        addComment(data);
      })
      .catch(err => setStatusMessage(`Заявка не закрыта или закрыта частично, требуется проверка. Ошибка: ${err.message}`));
  }

  function closeIssue() {
    setCloseIssueIsLoading(true);
    if (formValue.length === 1) {
      changeIssueStatus()
        .then(() => {
          if (isDiagIncluded) addDiagnosticService();
        })
        .then(() => changeEquipment(formValue[0]))
        .then(() => submitPhotos().catch(() => Promise.reject('Заявка закрыта с ошибкой. Не удалось загрузить фото!')))
        .then(() => {
          setCloseIssueIsLoading(false);
          resetForm();
          setIsConfirmPopupOpen(false);
          setStatusMessage('Заявка успешно закрыта');
        })
        .catch(err => setStatusMessage(err));
    }
    if (formValue.length > 1) {
      changeIssueStatus()
        .then(() => {
          if (isDiagIncluded) addDiagnosticService();
        })
        .then(() => formValue.forEach(item => {
          changeEquipment(item);
        }))
        .then(() => submitPhotos().catch(() => Promise.reject('Заявка закрыта с ошибкой. Не удалось загрузить фото!')))
        .then(() => {
          setCloseIssueIsLoading(false);
          resetForm();
          setIsConfirmPopupOpen(false);
          setStatusMessage('Заявка успешно закрыта');
        })
        .catch(err => setStatusMessage(err));
    }


  }

  function onEngineerSelectChange(selectedOption) {
    setSelectEngineer(selectedOption.value);
  }

  function onIssueSelectChange(selectedOption) {
    if (selectedOption) {
      setSelectIssue(selectedOption.value);
    } else setSelectIssue(undefined);
  }

  function onCommentChange(e) {
    setComment(e.target.value);
  }

  function onEqTypeChange(selectedOption) {
    if (selectedOption) {
      setSelectEqType(selectedOption.value);
    } else setSelectEqType(undefined);
  }


  function closeAllPopups() {
    setIsConfirmPopupOpen(false);
    setIsSNChangePopupOpen(false);
  }

  function onCloseIssue(e) {
    e.preventDefault();
    if (!selectIssue) {
      setStatusMessage('Выберите заявку');
      return;
    }
    if (!formValue[0]) {
      setStatusMessage('Заполните данные об оборудовании');
      return;
    }
    if (!selectEqType) {
      setStatusMessage('Выберите тип БО');
    } else {
      setStatusMessage('');
      setIsConfirmPopupOpen(true);
    }
  }

  function onChangeSN(eq, ref) {
    setSelectObjectEq(eq);
    setObjEqRef(ref);
    setIsSNChangePopupOpen(true);
  }

  function resetForm() {
    // меняем состояние, чтобы в потомке по useEffect сработала функция
    setIsResetForm(isResetForm => !isResetForm);
    document.getElementById('myForm').reset();
    selectIssueRef.current.clearValue();
    selectEqTypeRef.current.clearValue();
    setComment('');
    setFormValue([]);
  }

  async function compressImage(file, { quality = 1, type = file.type }) {
    // Get as image data
    const imageBitmap = await createImageBitmap(file);

    // Draw to canvas
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageBitmap, 0, 0);

    // Turn into Blob
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, type, quality)
    );
    // Turn Blob into File
    return new File([blob], file.name, {
      type: blob.type,
    });
  }

  // Get the selected file from the file input
  async function filesChange(e) {
    // Get the files
    const { files } = e.target;
    // No files selected
    if (!files.length) return;
    // We'll store the files in this data transfer object
    const dataTransfer = new DataTransfer();
    // For every file in the files list
    for (const file of files) {
      // We don't have to compress files that aren't images
      if (!file.type.startsWith('image')) {
        // Ignore this file, but do add it to our result
        dataTransfer.items.add(file);
        continue;
      }
      // We compress the file by 50%
      const compressedFile = await compressImage(file, {
        quality: 0.4,
        type: 'image/jpeg',
      });
      // Save back the compressed file instead of the original file
      dataTransfer.items.add(compressedFile);
    }
    // Set value of the file input to our new files list
    e.target.files = dataTransfer.files;
  }

  function onPhoto(e) {
    setCloseIssueIsLoading(true);
    filesChange(e)
      .then(() => setPhotosToUpload(e.target.files))
      .then(() => setCloseIssueIsLoading(false));
  }

  if (Object.keys(currentUser).length === 0) {
    return <Loader/>;
  } else return (<section className="close">
    <form id="myForm" className="close-issue-form">
      <h1 className="title">Закрытие заявки</h1>
      <div className="close-issue-form__wrap">
        {currentUser.isAdmin ? <Select isLoading={!engineersList[0]} isDisabled={!engineersList[0]} required
                                       placeholder="Выбрать исполнителя"
                                       className="close-issue-form__select" styles={customStyles}
                                       options={engineersList} onChange={onEngineerSelectChange}/> : ''}
        <Select isSearchable={false} isClearable ref={selectIssueRef} isLoading={issuesListLoading}
                isDisabled={issuesListLoading} name="selectIssue" required
                placeholder="Выбрать заявку"
                className="close-issue-form__select"
                styles={customStyles}
                options={expandedIssuesList} onChange={onIssueSelectChange}/>
        {selectIssue ? <a href={`${OKDESK_URL}issues/${selectIssue.id}`} target="_blank"
                          className="button close-issue-form__form-btn">Ссылка
          на заявку</a> : ''}
      </div>
      <div className="close-issue-form__wrapper">
        <ChangeEq isReset={isResetForm} diagnosticsServiceId={diagnosticsServiceId}
                  diagnosticIncludedHandler={setIsDiagIncluded} onChangeValue={setFormValue} formValue={formValue}
                  objectEquipmentsList={objectEqList} objEqListIsLoading={objectEqLoading}
                  engEqListIsLoading={engineerEqLoading}
                  engineerEquipmentsList={engineerEqList} servicesList={availablePriceList}
                  customStyles={customStyles} instanceNumber={0} isOpen={true}
                  onChangeSN={onChangeSN}/>
        <ChangeEq isReset={isResetForm} diagnosticsServiceId={diagnosticsServiceId}
                  diagnosticIncludedHandler={setIsDiagIncluded} onChangeValue={setFormValue} formValue={formValue}
                  objectEquipmentsList={objectEqList} objEqListIsLoading={objectEqLoading}
                  engEqListIsLoading={engineerEqLoading}
                  engineerEquipmentsList={engineerEqList} servicesList={availablePriceList}
                  customStyles={customStyles} instanceNumber={1} isOpen={false}
                  onChangeSN={onChangeSN}/>
        <ChangeEq isReset={isResetForm} diagnosticsServiceId={diagnosticsServiceId}
                  diagnosticIncludedHandler={setIsDiagIncluded} onChangeValue={setFormValue} formValue={formValue}
                  objectEquipmentsList={objectEqList} objEqListIsLoading={objectEqLoading}
                  engEqListIsLoading={engineerEqLoading}
                  engineerEquipmentsList={engineerEqList} servicesList={availablePriceList}
                  customStyles={customStyles} instanceNumber={2} isOpen={false}
                  onChangeSN={onChangeSN}/>
      </div>
      <div className="close-issue-form__wrap flex sm:gap-4 gap-1.5">
        <Select isSearchable={false} isClearable ref={selectEqTypeRef} isLoading={!props.eqTypeList[0]}
                isDisabled={!props.eqTypeList[0]}
                required placeholder="Выбрать тип БО"
                className="close-issue-form__select " styles={customStyles}
                options={props.eqTypeList} onChange={onEqTypeChange}/>
        <input className="close-issue-form__file-input" ref={fileInputRef} multiple onChange={onPhoto} type="file"
               accept="image/*"/>
      </div>
      <textarea name="commentInput" ref={commentInputRef} required onChange={onCommentChange} value={comment}
                placeholder="Ввести описание работ"/>
      <label>
        <input name="diagCheckbox" type="checkbox" checked={isDiagIncluded}
               onChange={() => setIsDiagIncluded(!isDiagIncluded)}/>
        Включить диагностику в заявку?
      </label>
      <div className="close-issue-form__btn-wrap">
        <p
          className={`close-issue-form__message ${statusMessage === 'Заявка успешно закрыта' ? 'close-issue-form__message_ok' : 'close-issue-form__message_err'}`}>{statusMessage}</p>
        <button disabled={closeIssueIsLoading} onClick={onCloseIssue}
                className="button close-issue-form__btn close-issue-form__submit-btn">Закрыть
          заявку
        </button>
      </div>
    </form>
    <Popup onClosePopup={closeAllPopups} isOpen={isConfirmPopupOpen}>
      <Confirm confirmBtnIsDisabled={closeIssueIsLoading} onSubmit={closeIssue} onClosePopup={closeAllPopups}
               isOpen={isConfirmPopupOpen}
               message={`Диагностика ${isDiagIncluded ? 'включена' : 'не включена'} в заявку`}/>
    </Popup>
    <Popup onClosePopup={closeAllPopups} isOpen={isSNChangePopupOpen}>
      <ChangeSN onSubmit={changeSN} onClosePopup={closeAllPopups} isOpen={isSNChangePopupOpen}
                selectObjectEq={selectObjectEq}/>
    </Popup>
  </section>);
}

export default Close;
