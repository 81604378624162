import React, {useContext} from 'react';
import CurrentUserContext from '../../../hoc/CurrentUserContext';
import './Profile.scss'

function Profile(props) {
  const currentUser = useContext(CurrentUserContext);

  return (
    <section className="profile">
      <h2>{currentUser.name}</h2>
      <p>{currentUser.email}</p>
      <button onClick={props.onLogout} className="button">Выйти из аккаунта</button>
    </section>
  );
}

export default Profile;
