import React, {useState} from 'react';
import './Register.scss';
import Popup from '../Popup/Popup';
import Confirm from '../Popup/Confirm/Confirm';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {SignupSchema} from '../../utils/validation';
import api from '../../utils/Api';

function Register(props) {
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [formValues, setFormValues] = useState(undefined)


  function onSubmit(values) {
    setFormValues(values)
    setIsConfirmPopupOpen(true);
  }

  function registerUser() {
    api.signup(formValues)
      .then(() => setStatusMessage('Пользователь успешно зарегестрирован'))
      .catch(err => setStatusMessage(`Не удалось зарегестрировать пользователя. Ошибка: ${err.message}`))
    closeAllPopups();
  }

  function closeAllPopups() {
    setIsConfirmPopupOpen(false);
  }

  return (
    <section className="register">
      <Formik initialValues={{
        name: '',
        email: '',
        password: '',
        api_key: '',
        isAdmin: false,
        okdeskId: '',
        storageId: '',
        companyId: ''
      }} validationSchema={SignupSchema} onSubmit={(values, helpers) => {
        // helpers.resetForm();
        onSubmit(values);
      }}>
        <Form className="register__form">
          <h1 className="title">Регистрация нового пользователя</h1>
          <div className="register__input-wrap">
            <label htmlFor="name">Имя</label>
            <Field type="text" name="name"/>
            <ErrorMessage name="name" component="div" className="register__validation-error"/>
          </div>
          <div className="register__input-wrap">
            <label htmlFor="email">E-mail</label>
            <Field type="email" name="email"/>
            <ErrorMessage name="email" component="div" className="register__validation-error"/>
          </div>
          <div className="register__input-wrap">
            <label htmlFor="password">Пароль</label>
            <Field autoComplete="new-password" type="password" name="password"/>
            <ErrorMessage name="password" component="div" className="register__validation-error"/>
          </div>
          <div className="register__input-wrap">
            <label htmlFor="api_key">API Key</label>
            <Field type="text" name="api_key"/>
            <ErrorMessage name="api_key" component="div" className="register__validation-error"/>
          </div>
          <div className="register__input-wrap">
            <label htmlFor="okdeskId">ID Пользователя в Окдеск</label>
            <Field type="number" name="okdeskId"/>
            <ErrorMessage name="okdeskId" component="div" className="register__validation-error"/>
          </div>
          <div className="register__input-wrap">
            <label htmlFor="storageId">ID Склада Пользователя в Окдеск</label>
            <Field type="text" name="storageId"/>
            <ErrorMessage name="storageId" component="div" className="register__validation-error"/>
          </div>
          <div className="register__input-wrap">
            <label htmlFor="companyId">ID Компании Пользователя в Окдеск</label>
            <Field type="text" name="companyId"/>
            <ErrorMessage name="companyId" component="div" className="register__validation-error"/>
          </div>

          <div className="register__checkbox-wrap">
            <label htmlFor="isAdmin">Администратор?</label>
            <Field type="checkbox" name="isAdmin"/>
            <ErrorMessage name="checkbox" component="div" className="register__validation-error"/>
          </div>
          <div className="register__btn-wrap">
            <p
              className={`register__message ${statusMessage === 'Пользователь успешно зарегестрирован' ? 'register__message_ok' : 'register__message_err'}`}>{statusMessage}
            </p>
            <button type="submit" className="button">Зарегестрировать</button>
          </div>
        </Form>
      </Formik>
      <Popup onClosePopup={closeAllPopups} isOpen={isConfirmPopupOpen}>
        <Confirm onSubmit={registerUser} onClosePopup={closeAllPopups} isOpen={isConfirmPopupOpen}/>
      </Popup>
    </section>

  );
}

export default Register;
