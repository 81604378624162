class Utils {
  generateSerialNumber(func) {
    new Date();
    const sn = Date.now();
    func(`${sn}`.slice(1, 11));
  }
}

const utils = new Utils();
export default utils;
