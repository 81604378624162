import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Минимум 2 символа')
    .max(40, 'Максимум 40 символов')
    .required('Обязательное поле'),
  email: Yup.string()
    .email('Неверный формат E-mail')
    .required('Обязательное поле'),
  password: Yup.string()
    .min(8, 'Минимум 8 символов')
    .required('Обязательное поле'),
  api_key: Yup.string()
    .length(40, 'Неправильная длина ключа')
    .required('Обязательное поле'),
  checkbox: Yup.boolean(),
  okdeskId: Yup.number()
    .typeError('Введите число')
    .required('Обязательное поле'),
  storageId: Yup.string()
    .required('Обязательное поле'),
  companyId: Yup.string()
    .required('Обязательное поле'),
});


const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email('Неверный формат E-mail')
    .required('Обязательное поле'),
  password: Yup.string()
    .min(8, 'Минимум 8 символов')
    .required('Обязательное поле'),
});

const CreateIssueSchema = Yup.object().shape({

});

export {SignupSchema, SigninSchema, CreateIssueSchema};
