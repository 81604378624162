import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.scss';
import Profile from '../Profile/Profile';
import Burger from '../Burger/Burger';
import CurrentUserContext from '../../../hoc/CurrentUserContext';

function Navbar({ handleLinkClick, onLogout, isMenuOpen, handleBurgerClick }) {

  const currentUser = useContext(CurrentUserContext);

  return (
    <header className="header">
      <Burger onClick={handleBurgerClick} isMenuOpen={isMenuOpen}/>
      <div className={`header__menu-wrapper ${isMenuOpen ? 'header__menu-wrapper_active' : ''}`}>
        <nav className="nav">
          <ul className="nav__list">
            {currentUser.isAdmin ? <li className="nav__item">
              <NavLink onClick={handleLinkClick} className=" link nav__link" to="/">Домой</NavLink>
            </li> : ''}
            <li className="nav__item">
              <NavLink onClick={handleLinkClick} className=" link nav__link" to="close">Закрыть заявку</NavLink>
            </li>
            {/*<li className="nav__item">*/}
            {/*  <NavLink onClick={handleLinkClick} className=" link nav__link" to="close_old">Закрыть заявку (old)</NavLink>*/}
            {/*</li>*/}
            <li className="nav__item">
              <NavLink onClick={handleLinkClick} className=" link nav__link" to="generate">Генерация SN</NavLink>
            </li>
            {currentUser.isAdmin ? <li className="nav__item">
              <NavLink onClick={handleLinkClick} className=" link nav__link" to="create-issue">Создать заявки в 1С</NavLink></li> : ''}
            {currentUser.isAdmin ? <li className="nav__item">
              <NavLink onClick={handleLinkClick} className=" link nav__link" to="add">Добавить</NavLink></li> : ''}
            {currentUser.isAdmin ? <li className="nav__item">
            {/*  <NavLink onClick={handleLinkClick} className=" link nav__link" to="patch">Изменить</NavLink></li> : ''}*/}
            {/*{currentUser.isAdmin ? <li className="nav__item">*/}
              <NavLink onClick={handleLinkClick} className=" link nav__link" to="register">Добавить пользователя</NavLink></li> : ''}
            {currentUser.isAdmin ? <li className="nav__item">
              <NavLink onClick={handleLinkClick} className=" link nav__link" to="settings">Настройки</NavLink></li> : ''}
          </ul>
        </nav>
        <Profile onLogout={onLogout}/>
      </div>
    </header>
  );
}

export default Navbar;
