import React from 'react';
import './ChangeSN.scss';
import Overlay from '../../Overlay/Overlay';
import {useFormWithValidation} from '../../../hooks/useFormWithValidation';

function ChangeSn({ onClosePopup, isOpen, selectObjectEq, onSubmit }) {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation();

function submitHandler(e) {
  e.preventDefault()
  onSubmit(values.serial_number)
  onClosePopup()
  values.serial_number = ''
}

  return (
    <>
      <Overlay onClose={onClosePopup} isActive={isOpen}/>
      <form className="change" id="change">
        <h2>Изменить SN</h2>
        <p>Текущий SN: <span>{selectObjectEq.serial_number}</span></p>
        <input required onChange={handleChange} value={values.serial_number} name="serial_number" placeholder="Введите новый серийный номер" type="text"/>
        <button onClick={submitHandler} disabled={!isValid} className="button">Сохранить изменения</button>
      </form>
    </>
  );
}

export default ChangeSn;
