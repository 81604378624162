import React, {useEffect, useState} from 'react';
import Form from '../Form/Form';
import api from '../../utils/Api';
import './Add.scss';
import Overlay from '../Overlay/Overlay';
import Popup from '../Popup/Popup';
import ProgressBar from '../ProgressBar/ProgressBar';

function Add(props) {

  const [findFormValue, setFindFormValue] = useState('');
  const [foundObjectId, setFoundObjectId] = useState('');
  const [modelsOptions, setModelsOptions] = useState([]);
  const [objectsAddressesOptions, setObjectsAddressesOptions] = useState([]);
  const [storagesOptions, setStoragesOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [statusPopupIsOpen, setStatusPopupIsOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [progressBarValue, setProgressBarValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    Promise.all([
      api.getData('objects_addresses')
        .then(res => setObjectsAddressesOptions(res.map(item => ({ value: item, label: item.name })))),
      api.getData('models')
        .then(res => setModelsOptions(res.map(item => ({ value: item.name, label: item.name })))),
      api.getData('companies')
        .then(res => setCompaniesOptions(res.map(item => ({ value: item.id, label: item.name })))),
      api.getData('storages')
        .then(res => setStoragesOptions(res.map(item => ({ value: item.id, label: item.name })))),
    ])
      .catch(e => console.log(`Невозможно выполнить запрос. Ошибка: ${e}`));
  }, []);


  function onAddObject(values) {
    const arr = values.object.split('\t');
    const body = {
      'name': arr[0],
      'company_id': values.companySelect,
      'address': values.parkSelect.name,
      'coordinates': values.parkSelect.coordinates,
      'custom_parameters': {
        'vin': arr[1],
        'asugpt_id': arr[2],
        'object_model': values.modelSelect
      }
    };
    api.postObject(body)
      .then(res => {
        console.log(res);
      })
      .catch(() => console.log('Ошибка внесения данных'));
  }

  function onAddObjects(values) {
    const arr = values.data.split(' ');
    arr.forEach(object => {
      setTimeout(() => onAddObject({
        object,
        parkSelect: values.objectAddressSelect,
        companySelect: values.companySelect,
        modelSelect: values.modelSelect
      }), 500);
    });
  }

  function onAddEquipments(values) {
    const arr = values.data.split(' ');
    arr.forEach(object => {
      setTimeout(() => onAddEquipment({
        object,
        companySelect: String(values.companySelect),
        storageSelect: values.storageSelect
      }), 500);
    });
  }


  function onAddEquipment(values) {
    const arr = values.object.split('\t');
    const body = {
      'equipment_type_code': arr[0],
      'equipment_manufacturer_code': arr[1],
      'equipment_model_code': arr[2],
      'serial_number': arr[3],
      'maintenance_entity_id': values.storageSelect,
      'company_id': values.companySelect
    };
    addEquipment(body)
      .catch(err => console.log(err));
  }

// Делим данные с инпута на строки и проходим по всем
  function onMassAddEquipments(values) {
    setIsLoading(true);
    setStatusPopupIsOpen(true);
    const arr = values.data.split(' ');
    const promises = arr.map((object, index) => {
      const dataArray = object.split('\t');
      return new Promise((resolve, reject) => setTimeout(() => {
        // setLoadingCounter(`${index + 1}/${arr.length}`);
        setProgressBarValue(100 / arr.length * (index));
        onMassAddEquipment({ dataArray, companySelect: values.companySelect })
          .then(() => resolve())
          .catch(error => reject(error));
      }, 8 * 500 * index));
    });
    return Promise.all(promises)
      .then(() => setTimeout(() => {
        setStatusMessage('Оборудование успешно добавлено');
        setProgressBarValue(0);
      }, 500))
      .catch(() => setTimeout(() => {
        setStatusMessage('Ошибка добавления обрудования');
        setProgressBarValue(0);
      }, 500))
      .finally(() => {
        setProgressBarValue(100);
        setIsLoading(false);
      });
  }

  function addEquipmentArrayAssembly(dataArray, companySelect, object) {
    return [
      {
        'equipment_type_code': 'cbk',
        'equipment_manufacturer_code': dataArray[1],
        'equipment_model_code': dataArray[2],
        'serial_number': dataArray[3],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`
      },
      {
        'equipment_type_code': 'router',
        'equipment_manufacturer_code': dataArray[4],
        'equipment_model_code': dataArray[5],
        'serial_number': dataArray[6],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`
      },
      {
        'equipment_type_code': 'dvr',
        'equipment_manufacturer_code': dataArray[7],
        'equipment_model_code': dataArray[8],
        'serial_number': dataArray[9],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`,
      },
      {
        'equipment_type_code': 'display_module',
        'equipment_manufacturer_code': dataArray[10],
        'equipment_model_code': dataArray[11],
        'serial_number': dataArray[12],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`,
      },
      {
        'equipment_type_code': 'front_board',
        'equipment_manufacturer_code': dataArray[13],
        'equipment_model_code': dataArray[14],
        'serial_number': dataArray[15],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`,
      },
      {
        'equipment_type_code': 'side_board',
        'equipment_manufacturer_code': dataArray[13],
        'equipment_model_code': dataArray[16],
        'serial_number': dataArray[17],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`,
      },
      {
        'equipment_type_code': 'side_board_2',
        'equipment_manufacturer_code': dataArray[13],
        'equipment_model_code': dataArray[18],
        'serial_number': dataArray[19],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`,
      },
      {
        'equipment_type_code': 'back_board',
        'equipment_manufacturer_code': dataArray[13],
        'equipment_model_code': dataArray[20],
        'serial_number': dataArray[21],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`,
      },
      {
        'equipment_type_code': 'display_module',
        'equipment_manufacturer_code': dataArray[22],
        'equipment_model_code': dataArray[23],
        'serial_number': dataArray[24],
        'company_id': `${companySelect}`,
        'maintenance_entity_id': `${object[0].id}`,
      },
    ];
  }

// Делим строки на ячейки и формируем объекты для отправки
  async function onMassAddEquipment({ companySelect, dataArray }) {
    const object = await api.getObject(dataArray[0]);
    const bodies = addEquipmentArrayAssembly(dataArray, companySelect, object);
    const promises = bodies.map((body, index) => {
      return new Promise((resolve, reject) => {
        if (body.serial_number !== '-') {
          setTimeout(() => {
            addEquipment(body)
              .then(() => resolve())
              .catch(error => reject(error));
          }, 500 * index);
        } else {
          resolve();
        }
      });
    });
    await Promise.all(promises);
  }



  // Отправляем, если вернулась ошибка по дублю - пробуем еще раз с другим серийником
  async function addEquipment(body) {
    api.postEquipment(body)
      .then(res => {
        if (res.serial_number[0] === "уже существует") {
          body.serial_number = body.serial_number + '-дубль';
          api.postEquipment(body)
            .then((res) => {
              console.log('Внесен задублированный серийный номер');
              return res;
            })
            .catch(() => setStatusMessage('Ошибка внесения данных'));
        }
        return res;
      })
      .catch(() => setStatusMessage('Ошибка внесения данных'));
  }


  // function handleFindFormChange(e) {
  //   setFindFormValue(e.target.value);
  // }

  // function handleFindObject(e) {
  //   e.preventDefault();
  //   api.getObject(findFormValue)
  //     .then(res => setFoundObjectId(res[0].id))
  //     .catch(() => setFoundObjectId(`Объект не найден`));
  // }

  function closeStatusPopup() {
    setStatusPopupIsOpen(false);
    setTimeout(() => setStatusMessage(''), 300);
  }

  return (
    <section className="add">

      {/*<div className="">*/}
      {/*  <h3 className="">{`Найти id объекта: ${foundObjectId}`}</h3>*/}
      {/*  /!*<span className="">{`ID искомого ТС: ${foundObjectId}`}</span>*!/*/}
      {/*  <form className="add__found-form">*/}
      {/*    <input className=""*/}
      {/*           onChange={handleFindFormChange} type="text"/>*/}
      {/*    <button*/}
      {/*      className="button"*/}
      {/*      onClick={handleFindObject}>Найти*/}
      {/*    </button>*/}
      {/*  </form>*/}
      {/*</div>*/}
      <Form type="addObject" title={'Добавить объекты'} onSubmit={onAddObjects}
            objectsAddressesOptions={objectsAddressesOptions} modelsOptions={modelsOptions}
            companiesOptions={companiesOptions}/>
      <Form type="eqInStorage" title={'Добавить оборудование на склад'} onSubmit={onAddEquipments}
            storagesOptions={storagesOptions} companiesOptions={companiesOptions}/>
      <Form type="eqOnBoard" title={'Добавить оборудование на ТС'} onSubmit={onMassAddEquipments}
            companiesOptions={companiesOptions}/>
      <Popup isOpen={statusPopupIsOpen}>
        <Overlay onClose={() => {
        }} isActive={statusPopupIsOpen}/>
        <div className="add__status-popup">
          <div className="add__status-message">
            {!statusMessage ? <><ProgressBar progressBarValue={progressBarValue}/></> : statusMessage}
          </div>
          <button disabled={isLoading} onClick={closeStatusPopup}
                  className="button">{isLoading ? 'Загрузка...' : 'Закрыть'}</button>
        </div>
      </Popup>
    </section>
  );
}

export default Add;
