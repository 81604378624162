import React from 'react';
import './Loader.scss';

function Loader(props) {
  return (
    <div className={`loader loader_type_${props.type}`}>
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default Loader;
