import React from 'react';

function SettingString(props) {

  function editButtonHandler() {
    props.onEditButton(props.item);
  }

  function removeButtonHandler() {
    props.onRemoveButton(props.item._id);
  }


  return (
    <li>
      <button onClick={removeButtonHandler}>
        <svg xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24"
             width="14" height="14">
          <polygon
            points="16.879 9.242 14.758 7.121 12 9.879 9.242 7.121 7.121 9.242 9.879 12 7.121 14.758 9.242 16.879 12 14.121 14.758 16.879 16.879 14.758 14.121 12 16.879 9.242"/>
          <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,3a9,9,0,1,0,9,9A9.01,9.01,0,0,0,12,3Z"/>
        </svg>
      </button>
      {/*<button onClick={editButtonHandler}>*/}
      {/*  <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24" width="14" height="14">*/}
      {/*    <path*/}
      {/*      d="M22.824,1.176a4.108,4.108,0,0,0-5.676,0L1.611,16.713A5.464,5.464,0,0,0,0,20.6v1.9A1.5,1.5,0,0,0,1.5,24H3.4a5.464,5.464,0,0,0,3.889-1.611L22.824,6.852A4.018,4.018,0,0,0,22.824,1.176ZM5.166,20.268A2.519,2.519,0,0,1,3.4,21H3v-.4a2.52,2.52,0,0,1,.732-1.768L15.3,7.267,16.733,8.7ZM20.7,4.731,18.854,6.58,17.42,5.146,19.27,3.3a1.037,1.037,0,0,1,1.433,0A1.015,1.015,0,0,1,20.7,4.731Z"/>*/}
      {/*  </svg>*/}
      {/*</button>*/}
      <span>{props.item.name}</span>
      {props.item.coordinates ? <span>{`- ${props.item.coordinates}`}</span> : ''}
      {props.item.id ? <span>{`id: ${props.item.id}`}</span> : ''}
    </li>
  );
}

export default SettingString;
