import React, {useEffect, useRef, useState} from 'react';
import './ChangeEq.scss';
import Select from 'react-select';
import utils from '../../utils/Utils';

function ChangeEq(props) {

  const [newSn, setNewSn] = useState('');
  const [selectObjectEq, setSelectObjectEq] = useState(undefined);
  const [value, setValue] = useState(undefined);
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const selectObjEqRef = useRef();
  const selectEngEqRef = useRef();
  const selectServiceRef = useRef();
  const newSnRef = useRef();

  useEffect(() => {
    if (selectEngEqRef.current) {
      selectEngEqRef.current.clearValue();
    }
    if (selectObjEqRef.current) {
      selectObjEqRef.current.clearValue();
    }
    if (selectServiceRef.current) {
      selectServiceRef.current.clearValue();
    }
    setValue(undefined)
    setNewSn('');
  }, [props.isReset, isOpen]);

  useEffect(() => {
    if (value) {
      let copy = Object.assign([], props.formValue);
      if (JSON.stringify(value) !== '{}') {
        copy[props.instanceNumber] = value;
      } else {
        copy.splice(props.instanceNumber);
      }
      props.onChangeValue(copy);
    }
  }, [value]);

  useEffect(() => {
    if (newSn) {
      putValueToState('newSn', newSn);
    } else deleteValueFromState('newSn');
  }, [newSn]);


  function changeSN(e) {
    e.preventDefault();
    props.onChangeSN(selectObjectEq, selectObjEqRef);
  }

  function addBtnHandler(e) {
    e.preventDefault();
    setIsOpen(prevState => !prevState);
  }

  function generateSerialNumber(e) {
    e.preventDefault();
    utils.generateSerialNumber(setNewSn);
  }

  function putValueToState(prop, value) {
    setValue((prevState) => ({
      ...prevState,
      [prop]: value
    }));
  }

  function deleteValueFromState(prop) {
    let copy = Object.assign({}, value);
    delete copy[prop];
    setValue(copy);
  }

  function onObjectEquipmentChange(selectedOption) {
    if (selectedOption) {
      setSelectObjectEq(selectedOption.value);
      putValueToState('selectObjEq', selectedOption.value);
    } else {
      setSelectObjectEq('');
      deleteValueFromState('selectObjEq');
    }
  }

  function onEngineerEquipmentChange(selectedOption) {
    if (selectedOption) {
      putValueToState('selectEngEq', selectedOption.value);
    } else deleteValueFromState('selectEngEq');
  }

  function onJobChange(selectedOption) {
    if (selectedOption) {
      if (props.diagnosticsServiceId && props.diagnosticsServiceId === selectedOption.value) {
        props.diagnosticIncludedHandler(false);
      } else {
        props.diagnosticIncludedHandler(true);
      }
      putValueToState('selectService', selectedOption.value);
    } else deleteValueFromState('selectService');
  }



  function onNewSnChange(e) {
    setNewSn(e.target.value);
  }

  return (
    <>
      {props.instanceNumber !== 0 ?
        // <button className="eq-change__add-btn" onClick={addBtnHandler}>{isOpen ? '-' : '+'}</button> : ''}

        <button className="eq-change__add-btn" onClick={addBtnHandler}><span
          className={`eq-change__add-btn-symbol eq-change__add-btn-symbol${!isOpen ? '_active' : ''}`}></span><span
          className={`eq-change__add-btn-symbol eq-change__add-btn-symbol${!isOpen ? '_active' : ''}`}></span>
        </button> : ''}
      <div className={`eq-change ${isOpen ? 'eq-change_active' : ''}`}>
        {!newSn ? <div className="eq-change__select-wrap">
          <Select isSearchable={false} isClearable required ref={selectObjEqRef} isLoading={props.objEqListIsLoading} isDisabled={props.objEqListIsLoading}
                  placeholder="Выбрать снятое оборудование" name={`selectObjectEq-${props.instanceNumber}`}
                  className="eq-change__select" styles={props.customStyles}
                  options={props.objectEquipmentsList} onChange={onObjectEquipmentChange}/>
          <button onClick={changeSN} disabled={!selectObjectEq} className="button  close-issue-form__form-btn">Сменить
            SN
          </button>
        </div> : ''}
        <Select isSearchable={false} isClearable required ref={selectEngEqRef}
                placeholder="Выбрать установленное оборудование" name={`selectEngineerEq-${props.instanceNumber}`}
                className="eq-change__select" styles={props.customStyles}  isLoading={props.engEqListIsLoading} isDisabled={props.engEqListIsLoading}
                options={props.engineerEquipmentsList} onChange={onEngineerEquipmentChange}/>
        <div className="eq-change__service-wrap">
          <Select isDisabled={props.servicesList.length === 0} isSearchable={false} isClearable required ref={selectServiceRef}
                  name={`selectService-${props.instanceNumber}`}
                  placeholder="Выбрать работу" className="eq-change__select eq-change__select_type_service"
                  styles={props.customStyles}
                  options={props.servicesList} onChange={onJobChange}/>
          {!selectObjectEq ?
            <div className="eq-change__input-wrap">
              <input ref={newSnRef} className="eq-change__sn-input" name={`snInput-${props.instanceNumber}`}
                     value={newSn}
                     onChange={onNewSnChange}
                     type="text" placeholder="Ввести новый SN"/>
              <button onClick={generateSerialNumber} className="button close-issue-form__form-btn">Сгенерировать SN
              </button>
            </div> : ''}
        </div>
      </div>
    </>
  );
}

export default ChangeEq;
