import React, {useEffect, useState} from 'react';
import './SettingTab.scss';
import Popup from '../../Popup/Popup';
import Overlay from '../../Overlay/Overlay';
import SettingString from './SettingString/SettingString';
import {Field, Form, Formik} from 'formik';
import api from '../../../utils/Api';

function SettingTab(props) {

  const [dataList, setDataList] = useState([]);
  const [editPopupIsOpen, setEditPopupIsOpen] = useState(false);
  const [addPopupIsOpen, setAddPopupIsOpen] = useState(false);
  const [fields, setFields] = useState({});

  useEffect(() => {
    api.getData(props.name)
      .then(res => setDataList(res))
      .catch(err => console.log(`Ошибка запроса: ${err}`));
  }, []);

  function addButtonHandler() {
    setAddPopupIsOpen(true);
  }

  function onRemoveButton(id) {
    api.removeData(props.name, id)
      .then(() => api.getData(props.name)
        .then(res => setDataList(res)))
      .catch(err => console.log(`Ошибка запроса: ${err.message}`));
  }

  function onEditButton(data) {
    setFields({
      name: data.name, coordinates: data.coordinates, id: data.id
    });
    setEditPopupIsOpen(true);
  }

  function saveChangesButtonHandler() {

  }

  function saveAddedButtonHandler(values, resetForm) {
    if (props.name === 'objects_addresses') {
      values.coordinates = values.coordinates.split(', ').map(item => Number(item));
    }
    if (props.name === 'storages') {
      values.id = String(values.id)
    }
    api.addData(props.name, values)
      .then(() => api.getData(props.name)
        .then(res => setDataList(res)))
      .then(() => resetForm())
      .catch(err => console.log(`Ошибка запроса: ${err}`));
    setAddPopupIsOpen(false);
  }

  function closeAllPopup() {
    setEditPopupIsOpen(false);
    setAddPopupIsOpen(false);
  }

  function submitHandler(values) {
    setEditPopupIsOpen(false);
    console.log(values);
  }

  return (<div className="setting-tab">
    <ul className="setting-tab__list">
      {dataList.map((item, index) => (
        <SettingString onRemoveButton={onRemoveButton} onEditButton={onEditButton} key={`${props.name}-${index}`}
                       item={item}
                       name={props.name} index={index}/>))}
    </ul>
    <button className="button setting-tab__add-button" onClick={addButtonHandler}>Добавить</button>
    <Popup isOpen={editPopupIsOpen}>
      <Overlay onClose={closeAllPopup} isActive={editPopupIsOpen}/>
      <Formik initialValues={fields} onSubmit={submitHandler} enableReinitialize>
        <Form className="edit-popup">
          <label className="edit-popup__field-wrap">
            Название
            <Field placeholder="Введите название" name="name" className="edit-popup__input" type="text"/>
          </label>
          {props.name === 'objects_addresses' ? <label className="edit-popup__field-wrap">
            Координаты
            <Field placeholder="Введите координаты" name="coordinates" className="edit-popup__input" type="text"/>
          </label> : ''}
          {props.name === 'companies' || props.name === 'storages' ? <label className="edit-popup__field-wrap">
            ID
            <Field placeholder="Введите ID" name="id" className="edit-popup__input" type="text"/>
          </label> : ''}
          <button className="button setting-tab__add-button" type="submit"
                  onClick={saveChangesButtonHandler}>Сохранить
          </button>
        </Form>
      </Formik>
    </Popup>
    <Popup isOpen={addPopupIsOpen}>
      <Overlay onClose={closeAllPopup} isActive={addPopupIsOpen}/>
      <Formik initialValues={{ name: '' }} onSubmit={(values, { resetForm }) => {
        saveAddedButtonHandler(values, resetForm);
      }} enableReinitialize>
        <Form className="edit-popup">
          <label className="edit-popup__field-wrap">
            Название
            <Field placeholder="Введите название" name="name" className="edit-popup__input" type="text"/>
          </label>
          {props.name === 'objects_addresses' ? <label className="edit-popup__field-wrap">
            Координаты
            <Field placeholder="Введите координаты" name="coordinates" className="edit-popup__input" type="text"/>
          </label> : ''}
          {props.name === 'companies' || props.name === 'storages' ? <label className="edit-popup__field-wrap">
            ID
            <Field placeholder="Введите ID" name="id" className="edit-popup__input" type="text"/>
          </label> : ''}
          <button className="button setting-tab__add-button" type="submit">Сохранить
          </button>
        </Form>
      </Formik>
    </Popup>
  </div>);
}

export default SettingTab;
