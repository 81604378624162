import React from 'react';
import './Confirm.scss';
import Overlay from '../../Overlay/Overlay';
import Loader from '../../Loader/Loader';

function Confirm({ isOpen, onClosePopup, onSubmit, confirmBtnIsDisabled, message }) {
  return (
    <>
      <Overlay onClose={onClosePopup} isActive={isOpen}/>
      <div className="confirm">
        <h2>{confirmBtnIsDisabled ? <Loader type={`popup`}/> : 'Вы уверены?'}</h2>
        {message && <p className="confirm__message">{message}</p>}
        <div className="confirm__btn-wrap">
          <button disabled={confirmBtnIsDisabled} onClick={onClosePopup} className="button">Нет</button>
          <button disabled={confirmBtnIsDisabled} onClick={onSubmit} className="button">Да</button>
        </div>
      </div>
    </>
  );
}

export default Confirm;
