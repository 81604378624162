import {API_URL, PRICE_LIST_WARRANTY} from './constants';

class Api {

  _onDoubleResponse(res) {
    if (res.status === 200) {
      return res.json();
    }
    if (res.status === 422) {
      return res.json();
    } else {
      return Promise.reject(`Ошибка запроса. Код ошибки: ${res.status}`);
    }
  }

  // _onResponse(res) {
  //   if (res.status === 200) {
  //     return res.json();
  //   } else {
  //     return Promise.reject(`Код ошибки: ${res.status}`);;
  //   }
  // }

  // альтернативная проверка ответа, для получения корректной ошибки
  _onResponse(res) {
    return res.json().then(body => {
      if (res.status === 200) {
        return body;
      } else {
        throw body;
      }
    });
  }

  signup(body) {
    return fetch(`${API_URL}/signup`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  login(email, password) {
    return fetch(`${API_URL}/signin`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(email, password)
    })
      .then(res => this._onResponse(res));
  }

  logout() {
    return fetch(`${API_URL}/signout`, {
      credentials: 'include', method: 'GET', headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => this._onResponse(res));
  }

  auth() {
    return fetch(`${API_URL}/users/me`, {
      credentials: 'include', method: 'GET', headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => this._onResponse(res));
  }

  postObject(body) {
    return fetch(`${API_URL}/objects`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  };

  getObject(name) {
    return fetch(`${API_URL}/objects/name/${name}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res));
  };

  getObjectName(id) {
    return fetch(`${API_URL}/objects/${id}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res))
      .then(res => res);
  };

  patchObject(id, body) {
    return fetch(`${API_URL}/objects/${id}`, {
      credentials: 'include', method: 'PATCH', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  getAllObjects = () => {
    let arr = [];
    this.getObjectPage()
      .then(res => res.forEach(item => arr.push(item)))
      .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      .then(res => res.forEach(item => arr.push(item)))
      .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      .then(res => res.forEach(item => arr.push(item)))
      .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      .then(res => res.forEach(item => arr.push(item)))
      .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      .then(res => res.forEach(item => arr.push(item)))
      .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      .then(res => res.forEach(item => arr.push(item)))
      .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      .then(res => res.forEach(item => arr.push(item)))
      .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      .then(res => res.forEach(item => arr.push(item)))
      // .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      // .then(res => res.forEach(item => arr.push(item)))
      // .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      // .then(res => res.forEach(item => arr.push(item)))
      // .then(() => this.getObjectPage(`${arr[arr.length - 1].id}`))
      // .then(res => res.forEach(item => arr.push(item)))
      .then(async () => {
        return arr.forEach(item => {
          this.getObject(item.name).then(res => {
            if (res[0].equipments_ids.length !== 7) {
              console.log(`${res[0].name} - ${res[0].equipments_ids}`);
            }
          });
        });
      })
      .then(() => console.log('done!'));
  };

  getEquipment(id) {
    return fetch(`${API_URL}/equipments/${id}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res));
  }

  getEquipmentsList(id) {
    return fetch(`${API_URL}/equipments/list/${id}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res));
  }

  patchEquipment(id, body) {
    return fetch(`${API_URL}/equipments/${id}`, {
      credentials: 'include', method: 'PATCH', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  postEquipment(body) {
    return fetch(`${API_URL}/equipments`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onDoubleResponse(res));
  }

  getIssuesParams() {
    return fetch(`${API_URL}/issues/parameters`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onDoubleResponse(res));
  }

  addService(id, body) {
    return fetch(`${API_URL}/issues/services/${id}`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  addPhotos(id, body) {
    return fetch(`${API_URL}/issues/photos/${id}`, {
      credentials: 'include', method: 'POST', body: body
    })
      .then(res => this._onResponse(res));
  }

  addComment(id, body) {
    return fetch(`${API_URL}/issues/comment/${id}`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  closeIssue(id, body) {
    return fetch(`${API_URL}/issues/close/${id}`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  createIssue(body) {
    return fetch(`${API_URL}/issues/create`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  getPriceList(id) {
    return fetch(`${API_URL}/nomenclature/price_list/${id}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onDoubleResponse(res));
  }

  getAvailablePriceList(issueId) {
    return fetch(`${API_URL}/issues/${issueId}/available_services`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onDoubleResponse(res));
  }

  getEngineers() {
    return fetch(`${API_URL}/engineers`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res));
  }

  getIssues(id) {
    return fetch(`${API_URL}/issues/get/${id}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res));
  }

  getIssue(id) {
    return fetch(`${API_URL}/issues/get-one/${id}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res));
  }

  getData(name) {
    return fetch(`${API_URL}/data/${name}`, {
      credentials: 'include', method: 'GET',
    })
      .then(res => this._onResponse(res));
  }

  addData(name, body) {
    return fetch(`${API_URL}/data/${name}`, {
      credentials: 'include', method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  patchData(name, body) {
    return fetch(`${API_URL}/data/${name}`, {
      credentials: 'include', method: 'PATCH', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify(body)
    })
      .then(res => this._onResponse(res));
  }

  removeData(name, id) {
    return fetch(`${API_URL}/data/${name}/${id}`, {
      credentials: 'include', method: 'DELETE'
    })
      .then(res => this._onResponse(res));
  }
}

const api = new Api();
export default api;
