import React, {useState} from 'react';
import './Generate.scss'

function Generate(props) {

  const [SN, setSN] = useState([]);

  function generateSerialNumber() {
    new Date();
    setSN([...SN, (<p>{`${Date.now()}`.slice(1, 11)}</p>)]);
  }

  function resetSN(e) {
    e.preventDefault();
    setSN([]);
  }

  return (
    <section className="generate">
      <h1 className='title'>Генерация серийных номеров</h1>
      <div className="generate__btn-wrap">
        <button
          className="form__btn button"
          onClick={generateSerialNumber}>Сгенерировать
        </button>
        <button
          className="form__btn button"
          onClick={resetSN}>Сбросить
        </button>
      </div>
      <div className="generate__numbers">{SN}</div>
    </section>
  );
}

export default Generate;
